export default {
  account: {
    setting: "Account settings",
    default_price_list: "Default price list for laboratory tests",
    pwz: "medical licence",
    personal_data: "Personal Data",
    email: "E-mail address",
    password: "Password",
    update_data: "Edit your data",
    change_password: "Change your password",
    blocked: "You have been blocked",
    contact_administrator: "Contact the administrator",
    key_customer_sector: "Key Customer Sector",
    keeper: "Keeper",
  },

  action: {
    change: "Change",
    see_details: "See details",
    see_doctor: "See doctor",
    see_referral: "See referral",
    go_back: "Return",
    save_changes: "Save changes",
    choose: "Select",
    continue: "Continue",
    save: "Save",
    clear: "Clean",
    return: "Return",
    filter: "Filter",
    withdraw_funds: "Withdraw funds",
    edit: "Edit",
    download: "Download",
    expand: "Expand",
    collapse: "Collapse",
    add: "Add",
    add_to_referral: "Dodaj do zlecenia",
    search: "Search",
    cancel: "Cancel",
    show_more: "Show more",
    delete: "Delete",
  },

  admin: {
    cities: {
      all: "All",
    },
    message: {
      title: "Title",
      message: "Content of the message",
      send: "Send a message",
      choose_doctors: "Select to whom you want to send the message",
      sent: "Your message has been sent to",
      recipients: "recipient(s)",
      recipients_number: "Number of recipients",
    },

    doctor: {
      create: "Add doctor",
      doctor_data: "Doctor's data",
      company_data: "Company data",
      address: "Address",
      account_number: "Bank account number",
      first_name: "Name",
      last_name: "Surname",
      pwz: "Medical Licence",
      main_medical_category: "Major medical category",
      email: "E-mail",
      registered_at: "Date of registration",
      phone_number: "Phone number",
      company_name: "Company/clinic name",
      nip: "NIP",
      pesel: "Pesel",
      birth_date: "Date of birth",
      street: "Street",
      house_number: "Building number",
      flat_number: "Premises number",
      postal_code: "Zip code",
      city: "City",
      edit: "Edit doctor",
      data_edit: "Edit data",
      referrrals_list: "Referrals list",
      send_message: "Send a message",
      status: "Status",
      full_name: "Full name",
      commission: "Salary",
      company: "Company",
      back_to_list: "Back to list of doctors",
      search_doctor: "Find a doctor",
      account_inactice: "The account is inactive",
      wait_for_activation:
        "Wait up to 24 hours for account validation from our staff",
      contact_info: "Contact us via chat from 6:00 a.m. to 11:30 p.m.",
      we_call_to_you: "We will call you",
      check_account_settings:
        "Check your account settings, add invoice details if you need them",
      go_to_settings: "Go to Settings",
    },

    invoices: {
      invoices: "Invoices",
      invoice_number: "Invoice number",
      amount: "Amount",
      doctor: "Doctor",
      account_number: "Account number",
      date_of_issue: "Date of issue",
      date_of_transfer: "Transfer date",
      download_all: "Download all",
      download_invoice: "Download an invoice",
      new: "New",
      paid: "Paid",
      amount_to_pay: "Amount to pay",
      select_month: "Select the month from which you want to download invoices",
      statuses: {
        new: "New",
        paid: "Paid",
        to_send: "To send",
        all: "All",
        sent: "Sent",
      },
      purchaser_name: "Purchaser",
      order_number: "Order number",
      date_of_order: "Date of order",
      date_execution: "Execution date",
      invoice_issued: "invoice_issued",
      mark_as_issued: "Mark as issued",
      mark_as_issued_confirm:
        "Confirm that you want to change the invoice status to issued",
      csv: "csv",
      status: "status",
      select_file: "Select file",
      add_file: "Add file",
    },

    promotions: {
      name: "Code Name",
      type: "Code Type",
      description: "Code Description",
      stepper: {
        next: "Next",
        prev: "Back",
        finish: "Finish",
      },
      discount_on_product: "Discount on the entire order",
      discount_per_order: "Discount on every matching product",
      buy_to_receive: "Buy X to receive Y",
      newsletter_discount: "Newsletter discount",
      numeric: "Numeric",
      alphanumeric: "Alphanumeric",
      alphabetical: "Alphabetical",
      discount: "Discount Amount",
      discount_type: "Type of Discount",
      is_personal_codes: "Personal Codes",
      what_examination_are_you_looking_for:
        "What research are you looking for?",
      which_products_does_this_promotion_apply_to:
        "Which products does this promotion apply to?",
      select_all_examinations: "Select all tests",
      select_all_bundles: "Select all packages",
      examination_name: "Examination Name",
      bundle_name: "Package Name",
      limited_promotion: "Limited promotion",
      number_of_promotions_for_the_code: "Number of promotions for the code",
      infinitely_many_for_the_code: "Infinitely many for the code",
      number_of_promotions_for_the_user: "Number of promotions for the user",
      base_amount: "Base amount",
      duration_of_the_promotion: "Duration of the promotion",
      from: "From",
      to: "To",
      enter_the_code: "Enter the code",
      new_code: "New code",
    },

    doctor_card: {
      status: "Status",
      referral_number: "Referral number",
      referral_barcode: "Barcode",
      total_price: "Price",
      commission: "Salary",
      patient: "Patient",
      date_of_issue: "Date of issue",
      date_of_examination: "Date of test",
      city: "City",
      street: "Street",
      postal_code: "Zip code",
      test_date: "Test date",
      import_date: "Import date",
      source: "Source",
      barcode: "Barcode",
      is_home_service: "Alab w domu",
    },
  },

  auth: {
    create_account: "Create an account",
    enter_code: "Enter your registration code",
    enter_code_below: " Enter this code below",
    enter_new_password: "Enter a new password for your account",
    email: "E-mail address",
    first_name: "Name",
    go_back_to_login: "Back to Login",
    have_account: "Already have an account?",
    last_name: "Surname",
    full_name: "First and last name",
    logout: "Logout",
    not_have_account: "Don't have an account yet?",
    not_receive_code: "The code did not reach me",
    not_remember_password: "Forgot your password?",
    password: "Password",
    password_confirm: "Repeat password",
    password_remind: "Remind password",
    password_reset: "Reset password",
    phone: "Phone No.",
    pwz: "Medical licence no.",
    register: "Register",
    reset: "Reset password",
    send_reset_link: "We will send a password reset link to your email address",
    sent_reset_link:
      "We sent you an SMS with the verification code to the number",
    sent_reset_link_email:
      "We sent you an email with a link to reset your password.",
    sent_reset_link_error:
      "The link has been sent, wait a moment to receive a new link.",
    reset_link_expired: "Your password reset link has expired",
    sign_in: "Log in",
    sign_up: "Register",
    sms: "SMS code",
    specialist_panel: "to the specialist panel",
    patient_panel: "to patient panel",
    verify: "Verify",
    pay_for_examinations: "to pay for examinations",
    enter_email_and_password: "Enter your email and password,",
    to_create_account: "to create a portal account",
    enter_phone_number: "Enter your phone number",
    enter_pesel: "Enter your pesel",
    login_by_pesel: "Register using your pesel",
    login_by_phone: "Register using your phone number",
    identity_card: "Proof No.",
    pesel: "Personal ID Number",
    current_password: "Current password",
    set_password: "Set password",
    salary: "Salary",
    patient_account: "to patient account",
    country_code: "Country code",
    phone_number: "Phone number",
    checkboxes: {
      information_obligation: "Information Duty.",
      agree: "I declare that I have read and accept the",
      agree_marketing:
        "I agree to the processing of my personal data, i.e. e-mail address, for the purpose of sending the newsletter electronically",
      agree_marketing_expand:
        " by ALAB laboratories Sp. z o.o. Stępińska 22/30, 00-739 Warsaw, in accordance with Article 10 of the Act of 18 July 2002 on the provision of services by electronic means (i.e. Journal of Laws 2017, item 1219). I declare that I have been informed about my rights, which I can read more about in the Information Duty",
      agree_contact:
        "I hereby agree that ALAB laboratoria Sp. z o.o. with its registered office in Warsaw",
      agree_contact_expand:
        ", 22/30 Stępińska Street, 00-739 Warsaw, may contact me for the purpose of discussing the terms and conditions of the agreement by means of telecommunication end devices and automatic calling systems according to the Act of 16 July 2004. - Telecommunications Law, i.e., inter alia, by means of a mobile phone",
      terms_of_service: "Terms of Service",
      service: "service",
      and: "and",
      privacy_policy: "Privacy Policy.",
      agree_processing_my_personal_data:
        "I agree to the processing of my personal data indicated in the above form for the purpose of creating an account.",
      agree_processing_my_personal_data_expand:
        " The data administrator is ALAB laboratories Sp. z o.o. Stępińska 22/30, 00-739 Warsaw. I declare that I have been informed about my rights, which I can read more about in the",
      terms: "Terms",
    },
    commission_options: {
      d15p0: "15% for the doctor, 0% for the patient",
      d10p5: "10% for the doctor, 5% for the patient",
      d5p10: "5% for the doctor, 10% for the patient",
      d0p15: "0% for the doctor, 15% for the patient",
      other: "Other",
    },
    birth_date: "Date of birth",
    day: "Day",
    month: "Month",
    year: "Year",
    no_pesel: "No Personal ID number",
    enter_pesel_number: "Enter your Personal ID number",
    enter_birth_date: "Enter your date of birth",
    gender: {
      title: "Sex",
      m: "",
      f: "Kobieta",
    },
  },

  cart: {
    selected_examinations: "Selected examinations",
    no_examinations: "You have not yet selected any examinations for referral.",
    nurse_service: "Nursing service",
    covid_service: "COVID Nursing Service",
    total: "Total",
    your_commission: "Your salary",
    discount_code: "Discount code",
    your_discount: "Discount",
    issue_referral: "Make a referral",
    edit_referral: "Edit referral",
    edit_referral_warning:
      "Editing this referral will issue a new referral and cancel the current referral.",
    pay_referral: "Pay",
    details: "Details",
    selected_collection_point: "Selected collection point",
  },

  collection_facility: {
    collection_facility_title: "Select a collection point",
    collection_facility_text:
      "Set for which point you want to have the price list of the laboratory tests shown. you can change it at any time in your profile.",
    search_placeholder: "City, street or postal code",
    current_location_error:
      "You did not allow your browser to download the location. Please try again.",
    hide_list: "Hide the list",
    show_list: "Show the list",
    show_filters: "Show filters",
    hide_filters: "Hide filters",
    show_on_map: "show_on_map",
    set_the_route: "set_the_route",
  },

  dashboard: {
    inaccessible_facility:
      "Your collection point is inactive or unable to perform tests.",
    inaccessible_facility_btn: "Change the collection point",
    commission_info:
      "The salary is the sum of the amounts payable for orders executed",
  },

  doctor_status: {
    blocked: "Blocked",
    active: "Active",
    inactive: "Inactive",
    deleted: "Deleted",
    all: "All",
  },

  empty_list: {
    referrals: "The referral list is empty.",
    doctors: "The list of doctors is empty.",
    patients: "The patient list is empty.",
    invoices: "The list of invoices is empty.",
  },

  error: {
    keeper_id: {
      required_if_empty: {
        has_keeper:
          "A keeper is required if the Key Customer Sector is selected.",
      },
    },
    accept_data_processing: {
      accepted: "Consent is required.",
    },
    agree_contact: {
      accepted: "Consent is required.",
    },
    accept_terms: {
      accepted: "Consent is required.",
    },
    accept_marketing: {
      accepted: "Consent is required.",
    },
    code: {
      required: "A sms code is required.",
      incorrect: "The code you entered is invalid.",
    },
    email: {
      already_taken: "The email you provided has already been used.",
      credentials_not_match: "The credentials are invalid.",
      email: "The email must be a valid email address.",
      required: "Email is required.",
      selected_invalid: "The specified email does not exist in the database.",
    },
    pesel: {
      required: "Personal ID number is required.",
      already_taken: "The personal ID number provided has already been used.",
      digits: "The personal ID number must be 11 in length.",
      invalid: "Incorrect personal ID number.",
      required_if_empty: {
        birth_date: "Personal ID number or date of birth is required.",
      },
      required_when_not_present: {
        phone_number: "Phone number or pesel is required.",
      },
      incorrect: "We can't find the user with the given identity pesel.",
    },
    birth_date: {
      required: "Date of birth is required.",
      required_if_empty: {
        pesel: "Personal ID number or date of birth is required.",
      },
      must_be_date_after_or_equal:
        "Date of birth must be greater than or equal to 01/01 1900.",
      must_be_a_date_before: "It is not possible to choose this date of birth.",
      invalid_date: "Date of birth is not a valid date.",
      incorrect: "Data urodzenia oraz pesel różnią się od siebie",
    },
    first_name: {
      required: "First name is required.",
    },
    last_name: {
      required: "Last Name is required.",
    },
    main_medical_category_id: {
      required: "Please select categories.",
    },
    current_password: {
      required: "The current password is required.",
      at_least_characters:
        "The current password must be at least 8 characters long.",
      invalid_format:
        "The current password must contain at least one lowercase letter, one uppercase letter, and one number.",
      incorrect: "The current password is incorrect.",
    },
    password: {
      required: "A password is required.",
      at_least_characters: "The password must be at least 8 characters long.",
      invalid_format:
        "The password must contain at least one lowercase letter, one uppercase letter, and one number.",
    },
    password_confirm: {
      required: "Password confirmation is required.",
      must_match: "The password and password confirmation must be the same.",
    },
    phone_number: {
      already_taken: "The phone number provided has already been used.",
      required: "Phone number is required.",
      digits: "The phone number must be 9 in length.",
      incorrect: "The phone number is incorrect.",
      choose_country: "Choose country",
      required_when_not_present: {
        pesel: "Phone number or pesel is required.",
      },
    },
    national_number: {
      required: "Phone number is required.",
    },
    phone_is_valid: {
      accepted: "Invalid phone number.",
    },
    pwz: {
      required: "Medical licence no. is required",
    },
    company_name: {
      required: "Company/clinic name is required.",
    },
    nip: {
      required: "NIP is required.",
      digits: "NIP must be 10 in length.",
    },
    street: {
      required: "Street is required.",
    },
    house_number: {
      required: "Building number is required.",
    },
    postal_code: {
      required: "Zip code is required.",
    },
    city: {
      required: "Locality is required.",
    },
    account_number: {
      required: "A bank account number is required.",
      invalid: "The bank account number is incorrect.",
    },
    agree1: {
      accepted: "Consent is required.",
    },
    identity_card: {
      required: "ID card number is required.",
    },
    title: {
      required: "Title is required.",
    },
    message: {
      required: "Message content is required.",
    },
    commission_option: {
      required: "The salary option is required.",
      selected_invalid: "The salary option must be one of the options above.",
    },
    is_verify: "An account with the given phone number already exists.",
    name: {
      required: "Name is required.",
    },
    promotions: {
      name: {
        required: "Code name is required.",
      },
      description: {
        required: "Code description is required.",
      },
      type: {
        required: "Code type is required.",
      },
      discount: {
        required: "Discount amount is required.",
        maxValue: "Discount amount cannot exceed 100%.",
        minValue: "Discount amount cannot be less than 0.",
      },
      type_discount: {
        required: "Discount type is required.",
      },
      use_limit_per_user: {
        required: "Number of promotions for the user is required.",
      },
      minimum_order_amount: {
        required: "Base amount is required.",
      },
      start_date: {
        beforeEndDate: "Start date must be earlier than end date.",
      },
      code_save_types: {
        required: "Method of generating code is required.",
      },
      file: {
        required: "File import is required.",
      },
      code: {
        required: "Code is required.",
      },
      amount_codes: {
        required: "Number of codes is required.",
      },
      length_limit: {
        required: "Number of code characters is required.",
      },
      code_type: {
        required: "Code format is required.",
      },
      suffix: {
        required: "Code suffix is required.",
      },
      prefix: {
        required: "Code prefix is required.",
      },
      packages: {
        minLength: "The promotion must include at least one test or package.",
      },
    },
  },

  error_page: {
    ups: "Oops, something went wrong. Wait a moment or try refreshing the page.",
    refresh: "Refresh",
  },

  examinations: {
    package: "Package",
    no_examinations: "No examinations.",
    no_packages: "No packages.",
  },

  filters: {
    status: "Status",
    referral_number: "Referral number",
    patient: "Patient",
    doctor: "Doctor",
    price: "Price",
    commission: "Salary",
    date_of_issue: "Date of issue",
    cost: "Cost of the examination",
    patient_discount_percent: "Discount percentage",
  },

  patient_discount_percent: {
    zero: "0 %",
    five: "5 %",
    ten: "10 %",
    fifteen: "15 %",
    all: "All",
  },

  homepage: {
    doctor: "Doctor",
    patient: "Patient",
    admin: "Admin",
    cms: "CMS",
    shop: "Shop",
    faq: "FAQ",
    about_us: "About us",
    contact: "Contact",
    catalog_of_examinations: "Catalog of examinations",
    cart: "Cart",
    my_referrals: "My referrals",
    discount_1: "To",
    discount_2: "For all research packages",
    discount_3: "Check out",
    highest_quality_research:
      "Perform the highest quality examinations in Poland",
    choose_from:
      "Choose from more than 3,500 examinations, 68 examination categories and packages.",
    join_special_actions:
      "Join special events, take advantage of special offers, create custom packages, and use a patient account with history and analysis of examination results.",
    shortcuts: "Shortcuts",
    morphology: "Morphology",
    glucose: "Glucose",
    cpr: "CRP",
    creatynine: "Creatynine",
    potassium: "Potassium",
    iron: "Iron",
    mercury_in_the_blood: "Mercury in blood",
    collection_points_1: "Over",
    collection_points_2: "collection points",
    research_every_year_1: "Over",
    research_every_year_2: "million",
    research_every_year_3: "examinations per year",
    years_on_the_market_1: "Over",
    years_on_the_market_2: "years",
    years_on_the_market_3: "on the market",
    what_examination_are_you_looking_for:
      "What kind of examination are you looking for?",
  },

  layouts: {
    issue_referral: "Make a referral",
    referral_history: "Referral history",
    patient_list: "Patient list",
    main_page: "Main page",
    examintons_list: "Catalog of examinations",
    own_packages: "Own packages",
  },

  medical_categories: {
    andrologist: "Andrologist",
    allergist: "Allergologist",
    dermatologist: "Dermatologist",
    gynecologist: "Gynecologist",
    orthopedist: "Orthopedist",
    obstetrician: "Obstetrician",
    diabetologist: "Diabetologist",
    internist: "Internist",
    pediatrician: "Pediatrician",
    psychologist: "Psychologist",
    dietician: "Dietician",
    cardiologist: "Cardiologist",
    urologist: "Urologist",
    endocrinologist: "Endocrinologist",
    family_doctor: "Family doctor",
    nurse: "Nurse",
    venereologist: "Venereologist",
    sports_medicine_physician: "Sports medicine physician",
    physiatrist: "Physiatrist",
    coach: "Coach",
    main_category: "Medical category",
    list: "Medical category",
    all: "All",
  },

  messages: {
    code: {
      resend: "The code was sent again.",
      resend_error: "An error occurred while submitting the code.",
    },
    edit: {
      success: "The changes were recorded.",
      failure: "An error occurred. Changes were not saved.",
    },
    cancel_referral: {
      success: "Comissioned test has been canceled.",
      failure: "An error occurred. Comissioned test has not been canceled.",
    },
    edit_amd_send_seferral: {
      success: "The changes were saved and the referral was resent.",
    },
    save: {
      success: "Saved successfully.",
      failure: "An error occurred. Failed to save.",
    },
    sms: {
      success: "The text message was sent again.",
      failure: "An error occurred. The text message has not been sent again.",
    },
    send: {
      failure: "An error occurred. The message could not be sent.",
    },
    payment_token: {
      failure:
        "An error occurred. Please try again or contact your administrator.",
    },
    permission: {
      failure: "It cannot be done.",
      forbidden:
        "The account is inactive, please contact the customer service office.",
    },
    download: {
      failure: "An error occurred while downloading the file.",
      no_invoice: "No invoices for the selected month.",
      no_files: "No files to download.",
    },
    activation_sms: {
      success: "Successfully sent sms code",
      failure: "There was a problem sending the SMS message.",
    },
    delete: {
      success: "Successfully deleted",
      failure: "A problem occurred during deletion.",
    },
  },

  not_found_page: {
    error: "Error",
    not_found: "Page not found",
    go_back: "Back to Home Page",
  },

  pages: {
    account: "Account settings",
    history_orders: "Referral history",
    my_settlements: "My accounts",
    doctors: "Doctors",
    referrals: "Referrals",
    invoices: "Invoices",
    your_referrals: "Your referrals",
    clientinvoices: "Customer invoices",
    raports: "Reports",
    referralErrors: "Error logs",
    promotions: "Promotions",
  },

  patients: {
    edit_patient: "Edit selected patient",
    edit: "Edit patient",
    no_patients: "You have no patients yet.",
    patient_in_system_assign_to_another_doc:
      "The patient is already in our system",
    request_access_to_historical_test_results:
      "For better diagnostics, ask for access to historical examination results",
  },

  referral_status: {
    doctor: {
      issued: "Issued",
      paid: "Paid",
      canceled: "Canceled",
      completed: "Completed",
      all: "All",
    },
    admin: {
      issued: "Issued",
      paid: "Paid",
      canceled: "Canceled",
      completed: "Completed",
      all: "All",
      visit_data_missing: "Order does not have a visit date selected",
    },
    patient: {
      issued: "New",
      paid: "Paid",
      canceled: "Canceled",
      completed: "Completed",
    },
  },

  empty_services: {
    packages: "No packages",
    examinations: "No examination",
    search: "No search term available",
  },

  own_packages: {
    examination_details: "Examination details",
    package_details: "Package details",
    add_to_own_package: "Add to your custom package",
    create_first_own_package:
      "Create your first custom package by typing its name below.",
    edit_own_package: "Edit the name of the custom package",
    your_own_packages: "Your custom packages",
    add_to_new_own_package: "Add to a new custom package",
    create_own_package: "Create a custom package",
    create_own_examination_package: "Create your custom package",
    other_examinations: "Other examinations",
    all_examinations: "All examinations",
    examination_packages: "Examinations packages",
    own_packages: "Custom packages",
    create_first_own_examination_package:
      "Create your first examination package",
    thanks_to_own_packages: "Thanks to custom packages,",
    faster_access:
      "you'll have faster access to the examinations you're often looking for",
    shorter_working_time:
      "you'll reduce the time you spend on your work and on issuing referrals",
    edit_package_name: "Edit package name",
    delete_package: "Delete package",
    add_to_cart: "Add to cart",
    add_to_cart_selected: "Add selected examinations",
    add_new_own_package: "Add a new custom package",
    update_own_package: "Edit custom package",
    name_own_package: "Name of custom package",
    delete_new_own_package: "Delete custom package",
    delete_new_own_package_confirm:
      "Are you sure you want to delete the package",
    examinations: "examinations",
    examinations_counter: "examinations",
    add_examinations_to_package: "Add another examination to the package",
    no_examinations_in_package_1:
      "You haven't added any research to your package yet",
    no_examinations_in_package_2: "How do I add research to a custom package?",
    no_examinations_in_package_3:
      "Поруч з кожним дослідженням (під його назвою) є синя зірочка з посиланням, яке дозволяє додати вибране дослідження до власного пакету",
    no_examinations_in_package_4:
      "Натисніть на нього і виберіть, до якого пакету ви хочете додати дослідження. Пам'ятайте, що ви можете створити нескінченну кількість пакетів, просто варто назвати їх так, щоб вони були зрозумілими і характерними для вас",
    no_examinations_in_package_5: "Перейдіть до списку з підбіркою досліджень",
  },

  referrals: {
    receive_VAT_invoice: "I want to receive a VAT invoice",
    to_receive_an_invoice:
      "To receive an invoice, please contact us at the following email address:",
    enter_study_name: "Enter a study name",
    referral: "Referral",
    number_short: "no.",
    examinations: "examinations",
    date_of_issue: "date of issue",
    date_of_test: "date of test",
    exact_price:
      "You can find out the exact price by selecting a collection point",
    doctor: "doctor",
    number: "Referral no.",
    issued_on: "Issued on",
    see_results: "See test results",
    commissioned_examinations: "Commissioned examinations",
    patient: "Patient",
    phone_number: "Phone No.",
    pesel: "Personal ID number",
    payment: "Payment",
    // commission: "Your salary",
    add_or_choose_patient:
      "Fill in the data for a new patient or find your patient in the list",
    issue_referrals: "Make referrals",
    patient_list: "List of patients",
    my_patient_list: "List of my patients",
    choose_patient: "Select a patient from the list",
    full_name: "First and last name",
    search_patient: "Enter your name, pesel or phone number",
    search_referral_patient: "Enter first or last name",
    send_sms_again: "Send sms again",
    edit_issued_referral: "Edit a commissioned test",
    cancel_issued_referral: "Cancel a comissioned test",
    issued_referral_text_part1:
      "You have made a referral. We have sent a text message to the patient with instructions to complete the order.",
    issued_referral_text_part2:
      "You will be paid when the patient pays for the referral.",
    referral_for_examination: "Ordering laboratory tests",
    referral_for_examination_text:
      "Printed orders can be filled by your patient directly at ALAB laboratories. ONLINE payment only.",
    print_referral: "Print referral",
    collection_facilities: "Points of collection",
    collection_facilities_text:
      "Find out where your patient can complete the referral",
    see_collection_facilities: "See collection points.",
    examination_findings: "Results of examination",
    examination_findings_text:
      "Test results will be available upon completion of the referral",
    unavailable: "Not available",
    waiting_for_payment: "Waiting for payment",
    waiting_for_commissions: "Waiting for the salary",
    price_online_payment: "Price for online payment at ALAB Online.pl",
    price_facility_payment: "Price for payment at point of collection Alab",
    commission_after_purchase:
      "Salary will be calculated after the patient buys the referral",
    no_referrals: "You do not have referrals made.",
    issue_referral: "Make a referral for testing",
    popular_examinations: "Most frequently selected examinations",
    package: "Package",
    added: "Added",
    number_of_referrals: "Number of referrals",
    average_number_of_referrals: "Average number of referrals",
    average_price_of_referral: "Average referral price",
    top_doctors: "Physicians who made referrals for the highest amount:",
    your_facility: "Collection point",
    choose_examinations: "Choose examinations",
    additionally_examinations:
      "If you wish, you may additionally perform other tests",
    ordered_examinations: "Reffered examinations",
    change: "Change data and resend referral",
    payments: {
      title: "Payments",
      amount: "Amount",
      currency: "Currency",
      order_id: "Order id",
      confirmed: "Is confirmed",
      created_at: "Created at",
      no_payment: "No payment registered.",
    },
    active: "Active order",
    details: "Details",
    email: "E-mail address",
    gender: "Gender",
    issued_at: "Order time",
    ip_address: "IP Address",
    browser: "Browser",
    pwz: "PWZ Number",
    collection_point: "Collection point",
    home_visit_address: "Home visit address",
    no_patient: "No patient data",
    no_collection_point: "No collection point",

    commission: {
      title: "Doctors commission",
      amount: "Commission amount",
      company: "Company name",
      nip: "NIP",
      account_number: "Account number",
    },

    workbeep: {
      title: "Home service",
      confirmed: "Confirmed reservation",
      workbeep_id: "Id workbeep reservation",
      visit_date: "Home visit date",
      no_confirmation: "No confirmation for reservation",
      no_address: "No address for home visit",
    },

    tabs: {
      packages: "Packages",
      examinations: "Examinations",
    },

    table: {
      id: "Referral ID",
      date: "Date",
      full_name: "First and last name",
      identity_card: "ID card number",
      status: "Status",
      phone: "Phone",
      number_of_examinations: "Number of examinations",
      commission: "Salary",
      show: "See the referral",
      show_patient: "See the patient",
      code: "Zip code",
      city: "City",
      street: "Street",
      cost: "Cost of examination",
      pesel: "Personal ID number",
      patient_discount_percent: "Discount percentage",
      patient_discount: "Discount",
    },
  },

  results: {
    download_pdf: "Download the results in PDF",
    back_to_referral: "Back to referral",
    choose_examination: "Select a examination to view results",
  },

  select_time: {
    current_month: "Current month",
    previous_month: "Previous month",
    last_90_days: "The last 90 days",
    last_6_months: "Last 6 months",
    current_year: "Current year",
    selected_from: "Select from calendar",
  },

  titles: {
    your_finances: "Your finances",
    your_stats: "Your statistics",
    stats: "Statistics",
    top_doctors: "Top Doctors",
  },

  your_finances: {
    to_pay: "To pay",
  },

  your_referrals: {
    see_results: "See test results",
    pay: "Pay for your examinations",
    see_summary: "See summary",
    just_paid: "You have already paid for this referral.",
    go_to_facility: "Go to the collection center to get your tests done.",
  },
  documents: {
    terms: "Terms of service",
    privacy_policy: "Privacy Policy",
  },

  your_settlements: {
    user_full_name: "First and last name",
    address: "Address",
    billing_data: "Invoice data",
    edit_billing_data: "Edit invoice details",
    my_settlements: "My settlements",
    verify_data_title: "Verify your data",
    verify_data_text:
      "Enter billing information to be able to bill wages on referrals issued",
    company_data_title: "Company data",
    company_name: "Company/clinic name",
    nip: "NIP",
    free_from_vat_title: "Are you exempt from VAT?",
    free_from_vat:
      "I declare that I am entitled to the tax exemption provided for in Article 113 paragraph 1 of the VAT Act.",
    address_title: "Address",
    street: "Street",
    house_number: "Building number",
    flat_number: "Premises number",
    postal_code: "Zip code",
    city: "City",
    account_number_title: "Bank account number",
    account_number: "Bank account number",
    verify_account: "Verify your account",
    your_invoices: "Your invoices",

    checkboxes: {
      check_all: "Select all",
      agree_terms: "I declare that I have read and accept",
      terms: "Terms",
      marketing:
        "I agree to the sending of commercial and marketing information via electronic means of communication.",
      agree3:
        "Donec dolor mi, interdum vel arcu vel, gravida sollicitudin est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed accumsan dui et sollicitudin porttitor",
      agree4:
        "Donec sed lacus leo. Fusce fermentum sem sed odio luctus, eu sollicitudin massa elementum. Maecenas pellentesque ligula libero, vel condimentum sem lobortis et",
    },

    invoice: {
      date: "Date",
      number: "Invoice number",
      amount: "Amount",
      download: "Download the invoice",
    },
  },

  share: {
    patient_examinations: "patient_examinations",
    approvals: "Consents",
    ask_for_access: "Ask for access to test results",
    ask_was_send: "ask_was_send",
    have_access: "have_access",
  },

  your_stats: {
    issued_referrals: "Referrals issued",
    paid_referrals: "Paid referrals",
    completed_referrals: "Completed referrals",
    canceled_referrals: "Cancelled referrals",
    commission: "Salary",
    cost: "Cost of testing",
  },
  payment: {
    made: "Payment made.",
    canceled: "Payment cancelled.",
    advance: "Waiting for payment confirmation.",
    returned: "Payment returned.",
    go_to_dashboard: "Go to main panel",
    retry_payment: "Re-payment",
  },

  category: {
    bundles_categories: "Package categories",
    examinations_categories: "Examination categories",
  },

  collection_points: {
    filters: {
      is_covid_private: "Adapted for COVID19",
      is_children: "Child-friendly",
      is_40_plus: "Prevention 40+",
      is_ginecology: "Gynecological",
      is_sport: "ALAB Sport",
      is_swab: "Swab collection",
      is_at_the_laboratory: "At the lab",
      open_sunday: "Open on Sunday",
      open_saturday: "Open on Saturday",
    },
    additional_info: "Additional information",
    additional_open: "additional_open",
    additional_close: "additional_close",
  },

  role: {
    patient: "Patient",
    doctor: "Doctor",
    admin: "Admin",
  },

  patient_details: {
    patient_info: "Patient information",
    order_history: "Order history",
    archive: "Results archive",
    consents_and_declarations: "Consents and declarations",
    send_sms_to_activate_account: "Send sms to activate account",
    edit_data: "Edit data",
    full_name: "First and last name",
    registered_at: "Date of registration",
    role: "Role",
    status: "Status",
    patient_delete_confirm_title: "Confirmation of patient deletion",
    patient_delete_confirm_info:
      "Are you sure you want to delete patient: {name}?",
    archive_order_delete_confirm_title:
      "Confirmation of deletion of results from the archive",
    archive_order_delete_confirm_info:
      "Are you sure you want to delete results with the number: {number}?",
    import_results: "Add results.",
    consents: {
      patient_consents: "Patient consents",
      current_consent: "Current consent",
      history_of_consents: "History of consents",
      date_of_consent: "Date of consent",
      marketing_consent: "Marketing consent",
      consent_to_data_processing: "Consent to data processing",
      browser: "Browser",
      no_consents: "No consent",
      consent_to_access_to_test_results: "Consent to access test results",
      awaiting_consent: "Awaiting consent",
      doctor: "Doctor",
      end_date_of_consent: "End date of consent",
      declarations: "declarations",
      date_of_statement: "Date of declaration",
      mame_of_child: "Child's name",
      declaration_accepted: "Declaration accepted",
      yes: "Yes",
      no: "No",
    },
  },
  yes: "Yes",
  no: "No",
};
